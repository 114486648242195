import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { propertyService } from 'modules/properties/PropertyService';
import { name } from 'utils/localized'
import clientStore from 'stores/client';
import propertyStore from 'stores/property';
import dayjs from "dayjs";
import LocaleStore from 'stores/locale';
import { rentService } from 'services/rentService';

import { Form, Button, Input, Select, Row, Col, DatePicker } from 'antd';

import { IClient } from "declarations/contact";
import { Property as IProperty } from "declarations/property";
import { IRent } from "declarations/rent";
import { some } from 'lodash';
import { useTranslation } from 'react-i18next';
dayjs.locale("en");

const dateFormat = 'DD.MM.YYYY';

interface IData {
  rent?: IRent,
  property?: IProperty;
  client?: IClient;
  onCancelButton(): void;
}

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const RentForm: FC<IData> = observer(({ rent, property, client, onCancelButton}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation();
  const lng = LocaleStore.locale;
  const shouldLog = useRef(true)

  useEffect(() => {
    if (rent && rent.id) {
      form.setFieldsValue({
        ...rent,
        start_date: dayjs(rent.start_date, dateFormat),
        end_date: dayjs(rent.end_date, dateFormat),
      })
    }
    if(property) {
      clientStore.fetchClients({}, 'all')
    } else {
      if(shouldLog.current) {
        shouldLog.current = false;
        propertyService.loadSelectResources()
      }
    }
  }, [])

  const handleSubmit = async () => {
    await form.validateFields();
    if (!some(form.getFieldsError(), ({ errors }) => {
      return errors.length
    })) {
      const values = form.getFieldsValue();
      let taskValue: IRent = {
        ...rent,
        ...values,
        start_date: values.start_date && dayjs(values.start_date).format(dateFormat) ,
        end_date: values.end_date && dayjs(values.end_date).format(dateFormat)
      };
      let filter = {};
      if(property){
        taskValue['property_id'] = property.id
        filter = { property_id: property.id }
      }
      if(client){
        taskValue['client_id'] = client.id
        filter = { client_id: client.id }
      }

      if (rent?.id) {
        rentService.updateResource(taskValue, onCancelButton)
      } else {
        await rentService.createResource(taskValue, filter, onCancelButton)
        if(client) {
          clientStore.fetchClient(client.id)
        }
      }
    }
  }

  const clients = clientStore.values;
  const properties = propertyStore.values;

  return (
    <div className="task-form">
      <Form form={form}>
        <Form.Item
          {...formItemLayout}
          label={t('properties.startDate')}
          name="start_date"
        >
          <DatePicker className='w-100p' format={dateFormat} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('properties.endDate')}
          name="end_date"
        >
          <DatePicker className='w-100p' format={dateFormat}/>
        </Form.Item>
        { property &&
          <Form.Item
            {...formItemLayout}
            label={t('rents.client')}
            name="client_id"
          >
            <Select
              showSearch
              placeholder={t('rents.placeholder.client')}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {(clients || []).map((client: IClient) => <Option value={client.id}>{client.name}</Option>)}
            </Select>
          </Form.Item>
        }
        { client &&
          <Form.Item
            {...formItemLayout}
            label={t('properties.table.property')}
            name="property_id"
          >
            <Select
              showSearch
              placeholder={t('properties.placeholder.property')}
              optionFilterProp="children"
              filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {(properties || []).map((property: IProperty) => <Option value={property.id}>{name(property, lng)}</Option>)}
            </Select>
          </Form.Item>
        }
        <Form.Item
          {...formItemLayout}
          label={t('properties.new.monthlyPrice')}
          name="monthly_price"
        >
          <Input placeholder="100€" addonAfter="€" />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={t('properties.table.status')}
          name="status"
          initialValue="active"
          rules={[{ required: true, message: t('validates.cantBeEmpty') }]}
        >
          <Select placeholder={t('properties.placeholder.status')} filterOption={(input: any, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            <Option value="active">{t('properties.new.status.active')}</Option>
            <Option value="inactive">{t('properties.new.status.inactive')}</Option>
          </Select>
        </Form.Item>
      </Form>
      <Row>
        <Col span={6}>
        </Col>
        <Col span={18}>
          <div className="form-controls">
            <Button type='primary' className='mr-10' onClick={handleSubmit}>{t('save')}</Button>
            <Button onClick={onCancelButton}>{t('cancel')}</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
})

export default RentForm;
